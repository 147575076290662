.marbles-game-show-broadcast {
  @media screen and (max-width: 768px) and (pointer: coarse) {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .dolby-feed {
    position: absolute;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    background: #000;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      background: transparent;
      overflow: unset;
      transition: all 500ms ease-in-out;
      height: calc(100svh - var(--bottom-panel-height, 0px));
    }

    .stream-error {
      height: 100%;
      width: 100%;
      @media screen and (max-width: 768px) and (pointer: coarse) {
        position: absolute;
        aspect-ratio: 16 / 9;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        height: 100%;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 75px;
          bottom: 0;
          background: linear-gradient(rgba(var(--black-color), 0), rgba(var(--black-color), 1));
          z-index: 9;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      position: relative;
      z-index: 3;
      border-radius: 10px;
    }

    .main-video-container {
      position: absolute;
      aspect-ratio: 16 / 9;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 75px;
          bottom: -1px;
          background: linear-gradient(rgba(var(--black-color), 0), rgba(var(--black-color), 1));
          z-index: 9;
        }
      }

      .stream-element,
      .stream-container {
        height: 100%;
      }

      .stream-container {
        img,
        video {
          @media screen and (max-width: 768px) and (pointer: coarse) {
            object-fit: cover;
          }
        }
      }
      img {
        object-position: center;
      }
      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        position: relative;
        z-index: 3;
        object-fit: contain;
        user-select: none;

        @media screen and (max-width: 768px) and (pointer: coarse) {
          border-radius: 0;
        }
      }

      audio {
        display: none;
      }

      .preview,
      .preview-0,
      .preview-1 {
        position: absolute;
        object-fit: cover;
        border-radius: 10px;
        z-index: 11;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        width: 180px;
        max-height: 120px;
        cursor: pointer;
        transition: all 150ms ease-in-out;

        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(0.95);
        }

        @media screen and (max-width: 768px) and (pointer: coarse) {
          width: 80px;
          height: 60px;
        }
      }
      .preview {
        top: 74px;
        left: 10px;
        @media screen and (max-width: 768px) and (pointer: coarse) {
          top: 5px;
          left: 5px;
        }
      }
      .preview-1 {
        top: 200px;
        left: 20px;

        @media screen and (max-width: 768px) and (pointer: coarse) {
          top: 80px;
          left: 10px;
        }
      }

      .preview-2 {
        top: 320px;
        left: 20px;

        @media screen and (max-width: 768px) and (pointer: coarse) {
          top: 160px;
          left: 10px;
        }
      }
    }

    .main-video {
      width: 100%;
      height: 100%;
      top: auto;
      right: auto;
      object-fit: contain;
      z-index: 3;
    }

    .dolby_video_player {
      position: relative !important;
      z-index: 9;
    }
  }
}

.stream-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid rgb(var(--yellow));
  width: 40px;
  height: 40px;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.stream-loading-message {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-formula-stack);

  animation: text-fancy-color 3s linear infinite reverse;

  background: linear-gradient(
    80deg,
    rgba(255, 255, 255, 0.8) 10%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.8) 60%
  );
  color: rgba(255, 255, 255, 0.6);

  background-clip: border-box;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% auto;
  -webkit-text-fill-color: transparent;
}
.stream-error-message,
.stream-inactive {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 28px;
  backdrop-filter: var(--primary-backdrop-filter-light);
  -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
  background: rgba(var(--primary-component-background), 0.9);
  box-shadow: var(--primary-background-shadow-with-inset);
  animation: stream-error-message 500ms ease-in-out;

  @media screen and (max-width: 440px) and (pointer: coarse) {
    transform: translate(-50%, -50%) scale(0.8);
  }

  .symbol {
    svg {
      width: 60px;
      height: 60px;
      transition: all 500ms ease-in-out;
      transform-style: preserve-3d;
      transform-origin: center;
      animation: rotate-symbol-loading 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      position: relative;
      margin-bottom: 10px;
      z-index: 3;
    }
  }

  @keyframes stream-error-message {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes rotate-symbol-loading {
    0% {
      transform: rotate(0deg);
    }
    33% {
      transform: rotate(-40deg);
      scale: 1.05;
    }
    66% {
      transform: rotate(40deg);
      scale: 0.95;
    }
    80% {
      transform: rotate(0deg);
      scale: 0.8;
    }
    100% {
      transform: rotateY(180deg);
      scale: 1;
    }
  }

  .message {
    white-space: nowrap;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-formula-stack);

    animation: text-fancy-color 3s linear infinite reverse;

    background: linear-gradient(
      80deg,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.8) 60%
    );
    color: rgba(255, 255, 255, 0.6);

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes text-fancy-color {
  to {
    background-position: 200% center;
  }
}
